<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>用户管理</a-breadcrumb-item>
    <a-breadcrumb-item>充值记录</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">日期：</span>
    <a-range-picker
      v-model:value="dateRange"
      :format="dateFormat"
    />
    <!-- UID： -->
    <span class="tool-title">UID：</span>
    <a-input v-model:value="uid" style="width: 160px;" placeholder="请输入" />
    <!-- 支付订单： -->
    <span class="tool-title">支付订单：</span>
    <a-input v-model:value="tradeNo" style="width: 160px;" placeholder="请输入" />
    <!-- 推广ID： -->
    <span class="tool-title">推广ID：</span>
    <a-input v-model:value="advID" style="width: 160px;" placeholder="请输入" />
    <!-- 平台： -->
    <span class="tool-title">平台：</span>
    <a-select v-model:value="platform" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option v-for="item in Platforms" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
    </a-select>
    <!-- 金额： -->
    <span class="tool-title">金额：</span>
    <a-select v-model:value="payMoney" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option v-for="item in moneyList" :key="item" :value="item">{{ item }}</a-select-option>
    </a-select>
    <!-- 短剧 -->
    <span class="tool-title">短剧：</span>
    <a-select
      style="width: 200px;"
      show-search
      v-model:value="dramaID"
      placeholder="请选择"
      :filter-option="filterOption"
    >
      <a-select-option
        :key="`0-用户中心`"
        :value="0"
      >
        用户中心
      </a-select-option>
      <a-select-option
        v-for="item in dramas"
        :key="`${item.project_drama_id}-${item.remark_name}`"
        :value="item.project_drama_id"
      >
        {{ item.remark_name }}
      </a-select-option>
    </a-select>
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <a-button style="margin-left: 16px;" @click="touchDownload">导出数据</a-button>
    <div style="flex: 1;"></div>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 推广ID -->
      <template v-if="column.key === 'adv_id'">
        <a-tooltip>
          <template #title>{{ record.adv_order_mapping && record.adv_order_mapping.adv_name || '-' }}</template>
          <span style="cursor: pointer;">{{ record.adv_order_mapping && record.adv_order_mapping.adv_id || '-' }}</span>
        </a-tooltip>
      </template>
      <!-- 充值次数 -->
      <template v-if="column.key === 'order_count'">
        <a class="operation-item" @click="handleTimes(record)">{{ record[column.key] }}</a>
      </template>
      <!-- 平台 -->
      <template v-if="column.key === 'platform'">
        {{ (Platforms.find(item => item.value === record.platform_id) || {}).text }}
      </template>
      <!-- 购买页面 -->
      <template v-if="column.key === 'buy_place'">
        <span>{{ record[column.key] }}{{ record.eq_number !== 0 ? `【第${record.eq_number}集】` : '' }}</span>
      </template>
      <!-- 其他信息 -->
      <template v-if="column.key === 'action'">
        <a @click="handleDetail(record)">查看</a>
      </template>
    </template>
  </a-table>
  <Detail ref="RefDetail" />
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { Platforms, PayChannels } from '@/utils/constantList'
import dayjs from 'dayjs'
import * as Ex from "@netjoy/excelex"
import Pub from '@/utils/public'
import Detail from './components-purchase/Detail.vue'
import { purchaseList, consumeMoneyList, projectDramaAll } from '@/api/operate'

// 详情抽屉
let RefDetail = ref(null)
// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 短剧列表
let dramas = ref([])
// 短剧ID
let dramaID = ref(undefined)
// 平台
let platform = ref(undefined)
// UID
let uid = ref(undefined)
// 推广id
let advID = ref(undefined)
// 推广id
let tradeNo = ref(undefined)
// 金额
let payMoney = ref(undefined)
// 金额列表
let moneyList = ref([])
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '购买时间',
    dataIndex: 'pay_success_time',
    key: 'pay_success_time'
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    key: 'user_id'
  },
  // {
  //   title: '昵称',
  //   dataIndex: 'username',
  //   key: 'username'
  // },
  {
    title: '充值次数',
    dataIndex: 'order_count',
    key: 'order_count'
  },
  {
    title: '推广ID',
    dataIndex: 'adv_id',
    key: 'adv_id'
  },
  {
    title: '客户端',
    dataIndex: 'platform',
    key: 'platform'
  },
  {
    title: '购买内容',
    dataIndex: 'charge_content',
    key: 'charge_content'
  },
  // {
  //   title: '原价',
  //   dataIndex: 'money',
  //   key: 'money'
  // },
  // {
  //   title: '抵扣券',
  //   dataIndex: 'coupon_name',
  //   key: 'coupon_name'
  // },
  {
    title: '金额',
    dataIndex: 'total_amount',
    key: 'total_amount'
  },
  {
    title: '购买页面',
    dataIndex: 'buy_place',
    key: 'buy_place'
  },
  {
    title: '其他信息',
    dataIndex: 'action',
    key: 'action'
  }
])

// 钩子函数
onBeforeMount (() => {
  // query带入uid字段
  const route = useRoute()
  if (route.query.id) {
    uid.value = route.query.id
  }
  // 获取购买列表
  getPurchaseList()
  // 获取金额列表
  getConsumeMoneyList()
  // 获取短剧列表
  getProjectDramaAll()
})

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getPurchaseList()
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getPurchaseList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = null
  uid.value = undefined
  advID.value = undefined
  tradeNo.value = undefined
  payMoney.value = undefined
  dramaID.value = undefined
  platform.value = undefined
  // 分页重置
  pagination.current = 1
  getPurchaseList()
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 短剧列表
function getProjectDramaAll () {
  projectDramaAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
    }
  })
}

// 获取金额列表
function getConsumeMoneyList () {
  consumeMoneyList().then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      moneyList.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取购买列表
function getPurchaseList () {
  isLoading.value = true
  const params = {
    start_at: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_at: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    uid: uid.value,
    adv_id: advID.value,
    trade_no: tradeNo.value,
    platform_id: platform.value,
    project_drama_id: dramaID.value,
    pay_money: payMoney.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  purchaseList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 查看充值详情：新开窗口超链至充值记录页检索该用户
function handleTimes (record) {
  Pub.OPEN_ROUTER('/operate-purchase-record', {
    id: record.user_id
  })
}

// 查看其他信息
function handleDetail (record) {
  RefDetail.value.showDrawer(record)
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    start_at: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_at: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    uid: uid.value,
    adv_id: advID.value,
    trade_no: tradeNo.value,
    platform_id: platform.value,
    project_drama_id: dramaID.value,
    pay_money: payMoney.value,
    page: 1,
    page_size: 100000
  }
  purchaseList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      if (list.length) {
        const columns = [
          {
            name: '购买时间',
            field: 'pay_success_time'
          },
          {
            name: 'UID',
            field: 'user_id'
          },
          {
            name: '充值次数',
            field: 'order_count'
          },
          {
            name: '推广ID',
            field: 'adv_id'
          },
          {
            name: '推广链接名称',
            field: 'adv_name'
          },
          {
            name: '客户端',
            field: 'platform'
          },
          {
            name: '购买内容',
            field: 'charge_content'
          },
          {
            name: '金额',
            field: 'total_amount'
          },
          {
            name: '购买页面',
            field: 'buy_place'
          },
          {
            name: '支付方式',
            field: 'pay_channel'
          },
          {
            name: '渠道流水ID',
            field: 'trade_no'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            if (field === 'adv_id') {
              item.data = json.adv_order_mapping && json.adv_order_mapping.adv_id || '-'
              return item
            } else if (field === 'adv_name') {
              item.data = json.adv_order_mapping && json.adv_order_mapping.adv_name || '-'
              return item
            } else if (field === 'platform') {
              item.data = (Platforms.find(item => item.value === json.platform_id) || {}).text
              return item
            } else if (field === 'pay_channel') {
              item.data = (PayChannels.find(item => item.value === json.pay_channel) || {}).text
              return item
            } else if (field === 'buy_place') {
              item.data = `${json.buy_place}${json.eq_number !== 0 ? `【第${json.eq_number}集】` : ''}`
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `用户管理-充值记录`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch((err) => {
    console.log(err)
    message.error('导出失败')
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>